'use client';

import { CodeHighlight } from '@mantine/code-highlight';
import { Button, Center, Space, Stack, Title } from '@mantine/core';
import { PropsWithChildren } from 'react';
import { ErrorBoundaryPropsWithComponent, ErrorBoundary as OriginalErrorBoundary } from 'react-error-boundary';

import { debug } from '@/lib/debug';

const ErrorFallback: ErrorBoundaryPropsWithComponent['FallbackComponent'] = ({ error, resetErrorBoundary }) => {
	// Call resetErrorBoundary() to reset the error boundary and retry the render.

	return (
		<Center h="100vh" role="alert" maw="80vw" m="auto">
			<Stack>
				<Title>Unhandled Error</Title>
				<Space />
				<pre style={{ color: 'red', whiteSpace: 'normal' }}>{error.message}</pre>
				<Space />
				<Button onClick={resetErrorBoundary}>reset</Button>
				<Space h="lg" />
				<CodeHighlight
					mah="50vh"
					maw="80vw"
					miw="400px"
					mih="200px"
					style={{ overflow: 'scroll' }}
					code={JSON.stringify(error, null, 2)}
					language="json"
				/>
			</Stack>
		</Center>
	);
};

const logError = (error: Error, info: unknown) => {
	debug('Unhandled Error', error, info);
};

export const ErrorBoundary: React.FC<PropsWithChildren> = ({ children }) => {
	return (
		<OriginalErrorBoundary FallbackComponent={ErrorFallback} onError={logError}>
			{children}
		</OriginalErrorBoundary>
	);
};
