'use client';

import * as Sentry from '@sentry/nextjs';
import React from 'react';

export const GlobalThisExtensions = () => {
	React.useEffect(() => {
		if (typeof window !== 'undefined') {
			// @ts-expect-error - global
			window.captureException = Sentry.captureException.bind(Sentry);
		}
	}, []);
	return null;
};
