'use client';

import { ModalsProvider as _ModalsProvider } from '@mantine/modals';

import { TestModal } from './TestModal';

export enum Modals {
	test = 'test',
}

const modals = {
	test: TestModal,
} satisfies Record<keyof typeof Modals, unknown>;

declare module '@mantine/modals' {
	export interface MantineModalsOverride {
		modals: typeof modals;
	}
}

export const ModalsProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
	return <_ModalsProvider modals={modals}>{children}</_ModalsProvider>;
};
