'use client';

import { Loader, Stack, Text } from '@mantine/core';
import { useActor } from '@xstate/react';
import { AnimatePresence, motion } from 'framer-motion';
import { useAtomValue } from 'jotai';
import { useEffect, useMemo, useState } from 'react';

import { userSessionService } from '@/providers/UserSessionProvider/user-session-state-machine';
import { appLoadingStateAtom } from '@/state/state-system';

const MDiv = motion.div;

export const AppLoadingScreen: React.FC<{ message?: string }> = ({ message }) => {
	return (
		<AnimatePresence presenceAffectsLayout>
			<MDiv
				key="loader"
				initial={{ opacity: 0 }}
				animate={{ opacity: 1 }}
				exit={{ opacity: 0, transition: { duration: 0.5 } }}
				style={{
					zIndex: 1000,
					position: 'fixed',
					inset: 0,
					backgroundColor: 'var(--mantine-color-body)',
					display: 'grid',
					placeContent: 'center',
				}}
			>
				<Stack justify="center" align="center">
					<Loader mb="lg" color="pink" size="sm" type="bars" />
					<Text c="dimmed" ta="center" mih={30}>
						{message}
					</Text>
				</Stack>
			</MDiv>
		</AnimatePresence>
	);
};

const LOADING_SCREEN_DEBOUNCE_SECONDS = 0.5;

export const LoadingScreen: React.FC<{ forceVisible?: boolean; message?: string }> = ({ forceVisible, message }) => {
	const [showLoading, setShowLoading] = useState(false);

	const appLoadingState = useAtomValue(appLoadingStateAtom);

	const [userSessionState] = useActor(userSessionService);

	const authMessage = useMemo(() => {
		if (userSessionState.matches('authentication.init')) return 'Authentifizierung';
		if (userSessionState.matches('authorization.init')) return 'Authorisierung';
		if (userSessionState.matches('authorization.account_validation')) return 'Benutzer-Session wird validiert';
		if (userSessionState.matches('authorization.account_hydration')) return 'Benutzer-Account wird geladen';

		return null;
	}, [userSessionState]);

	const loadingMessage = useMemo(() => {
		if (authMessage) return authMessage;

		if (appLoadingState.realtime) {
			return 'Echtzeitverbindung wird hergestellt';
		}
		return null;
	}, [appLoadingState.realtime, authMessage]);

	useEffect(() => {
		if (Boolean(loadingMessage) || forceVisible) {
			setShowLoading(true);
		} else {
			setShowLoading(false);
		}
	}, [forceVisible, loadingMessage, setShowLoading]);

	return (
		<AnimatePresence mode="wait">
			{showLoading ? (
				<MDiv
					key="app-preloader"
					initial={{ opacity: 0 }}
					// WARN: ANIMATE MUST be smaller than DEBOUNCE, otherwise the loading screen will remain visible forever
					animate={{ opacity: 1, transition: { duration: LOADING_SCREEN_DEBOUNCE_SECONDS / 2 } }}
					exit={{ opacity: 0, transition: { duration: LOADING_SCREEN_DEBOUNCE_SECONDS } }}
					style={{
						zIndex: 1000,
						position: 'fixed',
						inset: 0,
						backgroundColor: 'var(--mantine-color-body)',
						display: 'grid',
						placeContent: 'center',
					}}
				>
					<Stack justify="center" align="center">
						<Loader mb="lg" color="pink" size="sm" type="bars" />
						<Text c="dimmed" ta="center" mih={30}>
							{loadingMessage ? loadingMessage : message}
						</Text>
					</Stack>
				</MDiv>
			) : (
				<div key="blank" style={{ display: 'none' }} />
			)}
		</AnimatePresence>
	);
};
