'use client';

import { Provider } from 'jotai';
import * as React from 'react';

import { globalStore } from '@/state/store';

export const StateProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
	return <Provider store={globalStore}>{children}</Provider>;
};
