'use client';

import { createTheme, rem } from '@mantine/core';

export const theme = createTheme({
	/* Put your mantine theme override here */
	// radius: {
	// 	xs: '',
	// 	sm: '',
	// 	md: '',
	// 	lg: '',
	// 	xl: '',
	// },

	breakpoints: {
		xs: '30em',
		sm: '48em',
		md: '64em',
		lg: '85.375em',
		xl: '90em',
	},

	fontSizes: {
		xs: rem(10),
		sm: rem(12),
		md: rem(14),
		lg: rem(16),
		xl: rem(18),
	},
});
