import { Button, Paper, Stack, Text } from '@mantine/core';
import { type ContextModalProps } from '@mantine/modals';

export const TestModal = ({ context, id, innerProps }: ContextModalProps<{ modalBody: string }>) => (
	<Paper>
		<Stack mt="xl">
			<Text size="sm">{innerProps.modalBody}</Text>
			<Button fullWidth mt="md" onClick={() => context.closeModal(id)}>
				Close modal
			</Button>
		</Stack>
	</Paper>
);
